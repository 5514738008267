@media(max-width: 760px) {
    .body__style {
        margin-bottom: 30vh;
        margin-top: 1vh;
    }
}

.body__style {
    margin-top: 80px;
    position: relative;
    height: 100%;
    padding: 10px;
    padding-top: 15px;
}

.card__body__color {
    background-color: #010941 !important;
    border-color: #666870 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    color: #8ec0ec;
}

.li__style {
    background-color: #010941 !important;
    font-size: 15px;
    font-family: 'Courier New', Courier, monospace;
    color: #f5deb3;
    font-weight: 300;
}

.icon__style {
    margin-right: 0px;
    border-style: groove;
    border-color: bisque;
    border-top: none;
    border-width: medium;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 1px;
    border-radius: 3px;
}

.btn-color {
    background-color: #010941;
    border-color: #fff8eb;
    color: #fff8eb;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 500;
}

.icon__style:hover {
    background-color: #1f2c3d;
}

.title__h3 {
    text-align: left;
    color: #f5deb3;
    position: static;
}

.img__pixelate {
    image-rendering: pixelated;
}