@media(max-width: 768px) {
    .hero__body__style {
        margin-bottom: 30px;
    }
}

.hero__h5 {
    font-weight: 500 !important;
    font-size: 25px !important;
    color: #8ec0ec;
}

.hero__h1 {
    font-weight: bolder;
    font-size: clamp(60px, 8vw, 85px) !important;
    color: #f5deb3;
}

.hero__h2 {
    font-weight: bolder;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: clamp(60px, 8vw, 85px) !important;
    color: #8ec0ec;
}

.hero__p {
    color: #96b5cf;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.center {
    margin-top: 10px;
    position: relative;
    height: 100vh;
    transform: translate(0px, 15%);
    padding: 10px;
}

.hero__btn-color {
    background-color: #010941;
    border-color: #faf5ec;
    font-family: 'Courier New', Courier, monospace
}

.aStyle {
    color: #f5eee2;
    font-size: 20px;
    text-align: center;
    text-decoration: none;
}

.aStyle:hover {
    color: #8ec0ec;
    text-decoration: none;
}

.hero__btn-color:hover {
    background-color: #1f2c3d;
}