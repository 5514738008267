.contact__body__style {
    position: relative;
    margin-top: 90px;
}

.contact__form__border {
    border-color: wheat;
    border-style: dashed;
    border-width: 1px;
    border-radius: 10px;
    padding-bottom: 10px;
    margin: 10%;
}

#contact__p {
    color: #96b5cf;
    font-size: 18px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#contact__h3 {
    color: #f5deb3;
}

.p__validation {
    color: red;
}

.btn-custom {
    background-color: #010941 !important;
    border-color: #f5deb3 !important;
    color: #f5deb3 !important;
    font-family: 'Courier New', Courier, monospace !important;
    font-weight: 500 !important;
    padding: 5px !important;
    border-radius: 5px !important;
}

.btn-custom:hover {
    background-color: #1f2c3d !important;
}

.text__style {
    color: #f5deb3;
}

input[type=text]:focus {
    color: #9ec8ec;
    caret-color: blue;
}

input[type="submit"]:hover {
    background-color: #1f2c3d;
}

input:invalid {
    border: 2px solid red;
}

input:valid {
    border: 2px solid green;
}

textarea:invalid {
    border: 2px solid red;
}

textarea:valid {
    border: 2px solid green;
}